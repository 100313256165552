import Phaser from 'phaser';
import Player from './Player';

export default class MyPlayer extends Player {
  // private playerContainerBody: Phaser.Physics.Arcade.Body;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    frame?: string | number
  ) {
    super(scene, x, y, texture, id, frame);
    // this.playerContainerBody = this.playerContainer
    //   .body as Phaser.Physics.Arcade.Body;
  }

  update(cursors: Phaser.Types.Input.Keyboard.CursorKeys) {
    if (!cursors) return;

    const speed = 2;
    let vx = 0;
    let vy = 0;
    if (cursors.left?.isDown) {
      vx -= speed;
      this.angle = -90;
    }
    if (cursors.right?.isDown) {
      vx += speed;
      this.angle = 90;
    }
    if (cursors.up?.isDown) {
      vy -= speed;
      this.setDepth(this.y); //change player.depth if player.y changes
      this.angle = 0;
    }
    if (cursors.down?.isDown) {
      vy += speed;
      this.angle = 180;
      this.setDepth(this.y); //change player.depth if player.y changes
    }

    this.setVelocity(vx, vy);
    // this.body.velocity.setLength(speed);
    // also update playerNameContainer velocity
    // this.playContainerBody.setVelocity(vx, vy)
    // this.playContainerBody.velocity.setLength(speed)

    if (
      Math.abs(this.body.velocity.x) > 0.1 ||
      Math.abs(this.body.velocity.y) > 0.1
    ) {
      this.anims.play('fly', true);
    } else {
      this.anims.play('idle', true);
    }
  }
}

declare global {
  namespace Phaser.GameObjects {
    interface GameObjectFactory {
      myPlayer(
        x: number,
        y: number,
        texture: string,
        id: string,
        frame?: string | number
      ): MyPlayer;
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register(
  'myPlayer',
  function (
    this: Phaser.GameObjects.GameObjectFactory,
    x: number,
    y: number,
    texture: string,
    id: string,
    frame?: string | number
  ) {
    const sprite = new MyPlayer(this.scene, x, y, texture, id, frame);

    this.displayList.add(sprite);
    this.updateList.add(sprite);
    //
    // this.scene.physics.world.enableBody(
    //   sprite,
    //   Phaser.Physics.Arcade.DYNAMIC_BODY
    // );

    const collisionScale = [0.5, 0.2];
    // sprite.body
    //   .setSize(
    //     sprite.width * collisionScale[0],
    //     sprite.height * collisionScale[1]
    //   )
    //   .setOffset(
    //     sprite.width * (1 - collisionScale[0]) * 0.5,
    //     sprite.height * (1 - collisionScale[1])
    //   );

    return sprite;
  }
);
