import Phaser from 'phaser';
import '../characters/MyPlayer';
import MyPlayer from '../characters/MyPlayer';
import Player from '../characters/Player';

export default class Game extends Phaser.Scene {
  private map!: Phaser.Tilemaps.Tilemap;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  myPlayer!: MyPlayer;

  constructor() {
    super('game');
  }

  preload() {
    Player.preload(this);
    this.load.tilemapTiledJSON('tilemap', 'assets/maps/map.json');
    this.load.spritesheet('tiles_farm', 'assets/maps/farm.png', {
      frameHeight: 16,
      frameWidth: 16,
    });
    this.load.spritesheet('tiles_crop', 'assets/maps/crops.png', {
      frameHeight: 16,
      frameWidth: 16,
    });
  }

  registerKeys() {
    this.cursors = this.input.keyboard.createCursorKeys();
    // maybe we can have a dedicated method for adding keys if more keys are needed in the future
    // this.keyE = this.input.keyboard.addKey('E')
    // this.keyR = this.input.keyboard.addKey('R')
    this.input.keyboard.disableGlobalCapture();
    // this.input.keyboard.on('keydown-ENTER', (event) => {
    //   store.dispatch(setShowChat(true))
    //   store.dispatch(setFocused(true))
    // })
    // this.input.keyboard.on('keydown-ESC', (event) => {
    //   store.dispatch(setShowChat(false))
    // })
  }

  create(data: {}) {
    this.registerKeys();

    this.cameras.main.setBounds(0, 0, 1600, 1600);
    this.map = this.make.tilemap({ key: 'tilemap' });
    const farm = this.map.addTilesetImage('farm', 'tiles_farm');
    const crop = this.map.addTilesetImage('crops', 'tiles_crop');
    const groundLayer = this.map.createLayer('Ground', farm);
    this.map.createLayer('Road', farm);
    const envLayer = this.map.createLayer('Environment', farm);
    this.map.createLayer('Plants', [farm, crop])

    envLayer.setCollisionByProperty({ collides: true });
    this.matter.world.convertTilemapLayer(envLayer);
    // debugDraw(envLayer, this);

    this.myPlayer = this.add.myPlayer(400, 400, 'bee', '1');

    this.cameras.main.zoom = 2.5;
    this.cameras.main.startFollow(this.myPlayer, true);
  }

  update(t: number, dt: number) {
    if (this.myPlayer) {
      // this.playerSelector.update(this.myPlayer, this.cursors)
      this.myPlayer.update(this.cursors);

      this.cameras.main.centerOn(this.myPlayer.x, this.myPlayer.y);
    }
  }
}

const debugDraw = (
  layer: Phaser.Tilemaps.TilemapLayer,
  scene: Phaser.Scene
) => {
  const debugGraphics = scene.add.graphics().setAlpha(0.7);
  layer.renderDebug(debugGraphics, {
    tileColor: null,
    collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
    faceColor: new Phaser.Display.Color(40, 39, 37, 255),
  });
};
