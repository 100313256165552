import Phaser from 'phaser';

export default class Player extends Phaser.Physics.Matter.Sprite {
  playerId: string;
  playerTexture: string;
  // playerContainer: Phaser.GameObjects.Container;

  static preload(scene: Phaser.Scene) {
    scene.load.atlas(
      'bee',
      'assets/bees/bee.png',
      'assets/bees/bee_atlas.json'
    );
    scene.load.animation('bee_anim', 'assets/bees/bee_anim.json');
    // scene.load.spritesheet("items", itemSprite, {
    //   frameWidth: 32,
    //   frameHeight: 32,
    // });
    // scene.load.audio("player", hitAudio);
  }

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    frame?: string | number
  ) {
    super(scene.matter.world, x, y, texture, frame);

    this.playerId = id;
    this.playerTexture = texture;
    this.setDepth(this.y);
    this.setFixedRotation();

    // this.playerContainer = this.scene.add
    //   .container(this.x, this.y - 30)
    //   .setDepth(5000);
    //
    // this.scene.physics.world.enable(this.playerContainer);
    // const playContainerBody = this.playerContainer
    //   .body as Phaser.Physics.Arcade.Body;
    //
    // playContainerBody.drawDebug(scene.add.graphics().setAlpha(0.7));
    // const collisionScale = [0.5, 0.2];
    // playContainerBody
    //   .setSize(this.width * collisionScale[0], this.height * collisionScale[1])
    //   .setOffset(-8, this.height * (1 - collisionScale[1]) + 6);
  }
}
