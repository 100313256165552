import Phaser from 'phaser';
import Game from './scenes/Game';
import BootStrap from './scenes/BootStrap';

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  parent: 'phaser-game',
  backgroundColor: '#83924c',
  pixelArt: true,
  scale: {
    zoom: 1,
    autoCenter: Phaser.Scale.Center.CENTER_BOTH,
    mode: Phaser.Scale.ScaleModes.RESIZE,
    width: window.innerWidth,
    height: window.innerHeight,
  },
  physics: {
    default: 'matter',
    // arcade: {
    //   gravity: { y: 0 },
    //   debug: false,
    // },
    matter: {
      // debug: true,
      gravity: { y: 0 },
    },
  },
  autoFocus: true,
  scene: [Game],
};

const phaserGame = new Phaser.Game(config);
(window as any).game = phaserGame;

export default phaserGame;
